import { useAddPopup } from 'app/state/application/hooks'
import { useCallback } from 'react'

const ERRORS: any = {
  '1': 'Amount must be greater than 0',
  '2': 'Action requires an active reserve',
  '3': 'Action cannot be performed because the reserve is frozen',
  '4': 'The current liquidity is not enough',
  '5': 'User cannot withdraw more than the available balance',
  '6': 'Transfer cannot be allowed',
  '7': 'Borrowing is not enabled',
  '8': 'Invalid interest rate mode selected',
  '9': 'Collateral balance is 0',
  '10': 'Health factor is lesser than the liquidation threshold',
  '11': 'There is not enough collateral to cover a new borrow',
  '12': 'Stable borrowing not enabled',
  '13': 'Collateral is same currency that is being borrowed',
  '14': 'The requested amount is greater than the max loan size in stable rate mode',
  '15': 'No debt of selected type',
  '16': 'To repay on behalf of a user an explicit amount to repay is needed',
  '17': 'User does not have a stable rate loan in this reserve',
  '18': 'User does not have a variable rate loan in this reserve',
  '19': 'Underlying balance needs to be greater than 0',
  '20': 'User deposit is already being used as collatoral',
  '21': 'Not enough stable rate borrow balance',
  '22': 'Interest rate rebalance conditions not met',
  '23': 'Liquidation call failed',
  '24': 'Not enough liquidity to borrow',
  '25': 'Requested amount too small for flashloan',
  '26': 'The actual balance of protocol is inconsistent',
  '27': 'The caller of the function is not lending pool configurator',
  '28': 'Inconsistent flashloan params',
  '29': 'Caller must be lending pool',
  '30': 'User cannot give allowance to oneself',
  '31': 'Transfer amount needs to be > 0',
  '32': 'Reserve has already been initialised',
  '33': 'Caller not pool admin',
  '34': 'Liquidity of reserve needs to be 0',
  '35': 'Invalid aToken pool address',
  '36': 'Invalid stable debt token pool address',
  '37': 'Invalid variable debt token pool address',
  '38': 'Invalid address of underlying asset for stabled debt token',
  '39': 'Invalid address of underlying asset for variable debt token',
  '40': 'Invalid address provider id',
  '41': 'Provider is not registered',
  '42': 'Health factor not below threshold',
  '43': 'Collateral cannot be liquidated',
  '44': 'User did not borrow the specified currency',
  '45': 'Not enough liquidity to liquidate',
  '46': 'No errors',
  '47': 'Invalid flashloan mode',
  '48': 'Multiplication overflow',
  '49': 'Addition overflow',
  '50': 'Division by zero',
  '51': 'Liquidity index overflows',
  '52': 'Variable borrow index overflow',
  '53': 'Liquidity rate overflow',
  '54': 'Variable borrow rate overflow',
  '55': 'Stable borrow rate overflow',
  '56': 'Invalid mint amount',
  '57': 'Failed to repay with collateral',
  '58': 'Invalid burn amount',
  '59': 'Borrow allowance not enough',
  '60': 'Failded collateral swap',
  '61': 'Invalid equal assets to swap',
  '62': 'Reentrancy not allowed',
  '63': 'Caller must be an aToken',
  '64': 'Pool is paused',
  '65': 'No more reserves allowed',
  '66': 'Invalid amount returned from flash loan executor',
  '67': 'Invalid locked total value',
  '68': 'Invalid liquidity threshold',
  '69': 'Invalid liquidity bonus',
  '70': 'Invalid decimals',
  '71': 'Invalid reserve factor',
  '72': 'Invalid address provider id',
  '73': 'Inconsistent flashloan params',
  '74': 'Inconsistent params length',
  '75': 'Invalid configuration',
  '76': 'Caller is not an emergency admin',
  '77': 'Invalid index',
  '78': 'Not a contract',
  '79': 'Stable debt overflow',
  '80': 'Burn exceeds balance',
}

const useToastError = () => {
  const addPopup = useAddPopup()

  const showPopup = useCallback(
    async (executionRevertedMessage: string) => {
      try {
        let code: string[] | string = executionRevertedMessage.split('execution reverted: ')
        if (code.length === 2) {
          code = code[1]
          const error = ERRORS[code]
          addPopup({
            txn: { hash: '', summary: `Error ${code}${error ? ': ' : ''} ${error ?? ''}`, success: false },
          })
        }
      } catch (e) {}
    },
    [addPopup]
  )

  return {
    showPopup,
  }
}

export default useToastError
