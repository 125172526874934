import BigNumber from 'bignumber.js'

import { BigNumberValue } from './bignumber'

export const HEALTH_FACTOR_COLOR_CLASSES = ['text-red-500', 'text-orange-500', 'text-blue-500']

export function healthFactorColorClass(amount: BigNumberValue): string {
  let value
  if (amount === '') {
    value = new BigNumber(0)
  } else {
    value = new BigNumber(amount)
  }

  return HEALTH_FACTOR_COLOR_CLASSES[value.gte(1.5) ? 2 : value.gt(1) ? 1 : 0]
}
