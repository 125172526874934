import { CurrencyAmount, Token } from '@sushiswap/core-sdk'
import { GET } from 'app/config/sculptor/stake'
import { GET_TOKEN } from 'app/config/tokens'
import { GET_AVAR_TOKEN } from 'app/config/tokens/avar'
import { useActiveWeb3React } from 'app/services/web3'
import { BigNumber as BigNumberJS } from 'bignumber.js'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useSculptorAaveProtocolDataProviderContract, useSculptorLendingPoolContract } from '..'
import { useSculptorMasterChefContract, useSculptorUiDataCalculatorContract } from '../useContract'

function useReserve() {
  const { chainId } = useActiveWeb3React()
  const AVAR_TOKENS = GET_AVAR_TOKEN(chainId)

  const lendingPoolContract = useSculptorLendingPoolContract()
  const aaveProtocolDataProviderContract = useSculptorAaveProtocolDataProviderContract()

  const useAAndVarTokens = (tokenAddresses: string[]): Token[] => {
    const tokens: Token[] = tokenAddresses.map((address) => {
      return AVAR_TOKENS[address]
    })
    return tokens
  }

  const useReserveData = (token: Token | null | undefined): ReserveData | undefined => {
    const { chainId, account } = useActiveWeb3React()
    const masterChefContract = useSculptorMasterChefContract()

    const defaultResponse: ReserveData | undefined = useMemo(() => {
      return undefined
    }, [])

    const [reserveData, setReserveData] = useState<ReserveData | undefined>(defaultResponse)
    const fetchReserveData = useCallback(async () => {
      if (token === null || token === undefined) {
        return undefined
      }

      if (chainId) {
        try {
          const reserveDataRaw = await lendingPoolContract?.getReserveData(token.address)

          const response = {
            configuration: {
              data: reserveDataRaw.configuration.data,
            },
            liquidityIndex: reserveDataRaw.liquidityIndex,
            variableBorrowIndex: reserveDataRaw.variableBorrowIndex,
            currentLiquidityRate: CurrencyAmount.fromRawAmount(token, reserveDataRaw.currentLiquidityRate),
            currentVariableBorrowRate: CurrencyAmount.fromRawAmount(token, reserveDataRaw.currentVariableBorrowRate),
            currentStableBorrowRate: CurrencyAmount.fromRawAmount(token, reserveDataRaw.currentStableBorrowRate),
            lastUpdateTimestamp: reserveDataRaw.lastUpdateTimestamp,
            aTokenAddress: reserveDataRaw.aTokenAddress,
            stableDebtTokenAddress: reserveDataRaw.stableDebtTokenAddress,
            variableDebtTokenAddress: reserveDataRaw.variableDebtTokenAddress,
            interestRateStrategyAddress: reserveDataRaw.interestRateStrategyAddress,
            id: reserveDataRaw.id,
          }
          const extend = {
            variableBorrowAPY: new BigNumberJS(0),
          }

          setReserveData({
            ...response,
            ...extend,
          })
        } catch (error) {
          // setReserveData(defaultResponse)
          throw error
        }
      }
    }, [chainId, token])

    useEffect(() => {
      if (chainId && masterChefContract) {
        fetchReserveData()
      }
      const refreshInterval = setInterval(fetchReserveData, 10000)
      return () => clearInterval(refreshInterval)
    }, [chainId, account, fetchReserveData, masterChefContract])

    return reserveData
  }

  const useReservesData = (): ReserveDataWithBalance[] => {
    const { chainId, account } = useActiveWeb3React()
    const { SCULPT, SCULPTETH } = GET_TOKEN(chainId)
    const uiDataCalculatorContract = useSculptorUiDataCalculatorContract()

    const defaultResponse: ReserveDataWithBalance[] = useMemo(() => {
      return []
    }, [])

    const [reservesData, setReservesData] = useState<ReserveDataWithBalance[]>(defaultResponse)
    const fetchReservesData = useCallback(async () => {
      if (chainId) {
        try {
          const reservesListRaw = await lendingPoolContract?.getReservesList()

          const reserveDataPromises: Promise<any>[] = []
          const aaveProtocolDataReserveConfigPromises: Promise<any>[] = []
          const aaveProtocolDataReserveDataPromises: Promise<any>[] = []

          for (let i = 0; i < reservesListRaw.length; i++) {
            reserveDataPromises.push(lendingPoolContract?.getReserveData(reservesListRaw[i]))
            aaveProtocolDataReserveConfigPromises.push(
              aaveProtocolDataProviderContract?.getReserveConfigurationData(reservesListRaw[i])
            )
            aaveProtocolDataReserveDataPromises.push(
              aaveProtocolDataProviderContract?.getReserveData(reservesListRaw[i])
            )
          }

          const reservesDataRaw = await Promise.all(reserveDataPromises)
          const aaveProtocolDataReserveConfig = await Promise.all(aaveProtocolDataReserveConfigPromises)
          const aaveProtocolDataReserveData = await Promise.all(aaveProtocolDataReserveDataPromises)

          /* APYs */
          const apyPromises: Promise<string>[] = []
          for (let i = 0; i < reservesListRaw.length; i++) {
            apyPromises.push(
              uiDataCalculatorContract
                ?.calculateApr(GET(chainId).lendingPoolAddressesProviderAddress, reservesListRaw[i])
                .catch(() => {
                  return [0, 0]
                }),
              uiDataCalculatorContract
                ?.calculateAprIncentive(
                  GET(chainId).lendingPoolAddressesProviderAddress,
                  GET(chainId).chefIncentivesControllerAddress,
                  reservesListRaw[i],
                  SCULPTETH.address
                )
                .catch(() => {
                  return [0, 0]
                })
            )
          }
          const apys = await Promise.all(apyPromises)

          const response: ReserveDataWithBalance[] = []
          for (let i = 0; i < reservesDataRaw.length; i++) {
            const underlyingToken = AVAR_TOKENS[reservesListRaw[i]]
            response.push({
              liquidityIndex: reservesDataRaw[i].liquidityIndex,
              variableBorrowIndex: reservesDataRaw[i].variableBorrowIndex,
              currentLiquidityRate: CurrencyAmount.fromRawAmount(SCULPT, reservesDataRaw[i].currentLiquidityRate),
              currentVariableBorrowRate: CurrencyAmount.fromRawAmount(
                SCULPT,
                reservesDataRaw[i].currentVariableBorrowRate
              ),
              currentStableBorrowRate: CurrencyAmount.fromRawAmount(SCULPT, reservesDataRaw[i].currentStableBorrowRate),
              lastUpdateTimestamp: reservesDataRaw[i].lastUpdateTimestamp,
              aTokenAddress: reservesDataRaw[i].aTokenAddress,
              stableDebtTokenAddress: reservesDataRaw[i].stableDebtTokenAddress,
              variableDebtTokenAddress: reservesDataRaw[i].variableDebtTokenAddress,
              interestRateStrategyAddress: reservesDataRaw[i].interestRateStrategyAddress,
              id: reservesDataRaw[i].id,

              underlyingTokenAddress: reservesListRaw[i],

              depositApy: CurrencyAmount.fromRawAmount(SCULPT, apys[i * 2 + 0][0]),
              depositIncentiveApy: CurrencyAmount.fromRawAmount(SCULPT, apys[i * 2 + 1][0]),
              borrowApy: CurrencyAmount.fromRawAmount(SCULPT, apys[i * 2 + 0][1]),
              borrowIncentiveApy: CurrencyAmount.fromRawAmount(SCULPT, apys[i * 2 + 1][1]),

              reserveConfiguration: {
                decimals: new BigNumberJS(aaveProtocolDataReserveConfig[i].decimals._hex).toNumber(),
                ltv: new BigNumberJS(aaveProtocolDataReserveConfig[i].ltv._hex).div(1e2),
                liquidationThreshold: new BigNumberJS(aaveProtocolDataReserveConfig[i].liquidationThreshold._hex).div(
                  1e2
                ),
                liquidationBonus: new BigNumberJS(aaveProtocolDataReserveConfig[i].liquidationBonus._hex)
                  .minus(1e4)
                  .div(1e2),
                reserveFactor: new BigNumberJS(aaveProtocolDataReserveConfig[i].reserveFactor._hex),
                usageAsCollateralEnabled: aaveProtocolDataReserveConfig[i].usageAsCollateralEnabled,
                borrowingEnabled: aaveProtocolDataReserveConfig[i].borrowingEnabled,
                stableBorrowRateEnabled: aaveProtocolDataReserveConfig[i].stableBorrowRateEnabled,
                isActive: aaveProtocolDataReserveConfig[i].isActive,
                isFrozen: aaveProtocolDataReserveConfig[i].isFrozen,
              },

              reserveData: {
                availableLiquidity: CurrencyAmount.fromRawAmount(
                  underlyingToken,
                  aaveProtocolDataReserveData[i].availableLiquidity
                ),
                totalStableDebt: CurrencyAmount.fromRawAmount(
                  underlyingToken,
                  aaveProtocolDataReserveData[i].totalStableDebt
                ),
                totalVariableDebt: CurrencyAmount.fromRawAmount(
                  underlyingToken,
                  aaveProtocolDataReserveData[i].totalVariableDebt
                ),
                liquidityRate: new BigNumberJS(aaveProtocolDataReserveData[i].liquidityRate._hex),
                variableBorrowRate: new BigNumberJS(aaveProtocolDataReserveData[i].variableBorrowRate._hex),
                stableBorrowRate: new BigNumberJS(aaveProtocolDataReserveData[i].stableBorrowRate._hex),
                averageStableBorrowRate: new BigNumberJS(aaveProtocolDataReserveData[i].averageStableBorrowRate._hex),
                liquidityIndex: new BigNumberJS(aaveProtocolDataReserveData[i].liquidityIndex._hex),
                variableBorrowIndex: new BigNumberJS(aaveProtocolDataReserveData[i].variableBorrowIndex._hex),
                lastUpdateTimestamp: new BigNumberJS(aaveProtocolDataReserveData[i].lastUpdateTimestamp._hex),
              },
            })
          }
          setReservesData(response)
        } catch (error) {
          // setReservesData(defaultResponse)
          throw error
        }
      }
    }, [SCULPT, SCULPTETH.address, chainId, uiDataCalculatorContract])

    useEffect(() => {
      if (chainId) {
        fetchReservesData()
      }
      const refreshInterval = setInterval(fetchReservesData, 10000)
      return () => clearInterval(refreshInterval)
    }, [chainId, account, fetchReservesData])

    return reservesData
  }

  const useUserAccountData = (token: Token | null | undefined): UserAccountData | undefined => {
    const { chainId, account } = useActiveWeb3React()
    const { SCULPT } = GET_TOKEN(chainId)

    // TODO recheck if it's ok to use SCULPT as currency

    const defaultResponse: UserAccountData | undefined = useMemo(() => {
      return {
        totalCollateralETH: CurrencyAmount.fromRawAmount(SCULPT, '0'),
        totalDebtETH: CurrencyAmount.fromRawAmount(SCULPT, '0'),
        availableBorrowsETH: CurrencyAmount.fromRawAmount(SCULPT, '0'),
        currentLiquidationThreshold: new BigNumberJS(0),
        ltv: new BigNumberJS(0),
        healthFactor: CurrencyAmount.fromRawAmount(SCULPT, '0'),
      }
    }, [SCULPT])

    const [userAccountData, setUserAccountData] = useState<UserAccountData | undefined>(defaultResponse)
    const fetchUserAccountData = useCallback(async () => {
      if (token === null || token === undefined) {
        return undefined
      }

      if (account) {
        try {
          const userAccountDataRaw = await lendingPoolContract?.getUserAccountData(account)

          const response: UserAccountData = {
            totalCollateralETH: CurrencyAmount.fromRawAmount(SCULPT, userAccountDataRaw.totalCollateralETH),
            totalDebtETH: CurrencyAmount.fromRawAmount(SCULPT, userAccountDataRaw.totalDebtETH),
            availableBorrowsETH: CurrencyAmount.fromRawAmount(SCULPT, userAccountDataRaw.availableBorrowsETH),
            currentLiquidationThreshold: new BigNumberJS(userAccountDataRaw.currentLiquidationThreshold._hex ?? 0),
            ltv: new BigNumberJS(userAccountDataRaw.ltv._hex).div(1e2),
            healthFactor: CurrencyAmount.fromRawAmount(SCULPT, userAccountDataRaw.healthFactor),
          }

          setUserAccountData(response)
        } catch (error) {
          // setUserAccountData(defaultResponse)
          throw error
        }
      }
    }, [token, account, SCULPT])

    useEffect(() => {
      if (account) {
        fetchUserAccountData()
      }
      const refreshInterval = setInterval(fetchUserAccountData, 10000)
      return () => clearInterval(refreshInterval)
    }, [chainId, account, fetchUserAccountData])

    return userAccountData
  }

  const useUserReserveData = (underlyingTokenAddress: string): UserReserveData => {
    const { chainId, account } = useActiveWeb3React()

    const defaultResponse: UserReserveData = useMemo(() => {
      return {
        usageAsCollateralEnabled: false,
      }
    }, [])

    const [userReserveData, setUserReserveData] = useState<UserReserveData>(defaultResponse)
    const fetchUserReserveData = useCallback(async () => {
      if (account && underlyingTokenAddress !== '') {
        try {
          const aaveProtocolDataProvider = await aaveProtocolDataProviderContract?.getUserReserveData(
            underlyingTokenAddress,
            account
          )

          const response: UserReserveData = {
            usageAsCollateralEnabled: aaveProtocolDataProvider.usageAsCollateralEnabled,
          }

          setUserReserveData(response)
        } catch (error) {
          // setUserReserveData(defaultResponse)
          // throw error // TODO
        }
      }
    }, [account, underlyingTokenAddress])

    useEffect(() => {
      if (account) {
        fetchUserReserveData()
      } else {
        setUserReserveData(defaultResponse)
      }
      const refreshInterval = setInterval(fetchUserReserveData, 10000)
      return () => clearInterval(refreshInterval)
    }, [chainId, account, fetchUserReserveData, defaultResponse])

    return userReserveData
  }

  const useUserReservesData = (): UserReserveData[] => {
    const { chainId, account } = useActiveWeb3React()

    const defaultResponse: UserReserveData[] = useMemo(() => {
      return []
    }, [])

    const [userReservesData, setUserReservesData] = useState<UserReserveData[]>(defaultResponse)
    const fetchUserReservesData = useCallback(async () => {
      if (account) {
        try {
          const reservesListRaw = await lendingPoolContract?.getReservesList()

          const userReserveDataPromises: Promise<any>[] = []
          for (let i = 0; i < reservesListRaw.length; i++) {
            userReserveDataPromises.push(
              aaveProtocolDataProviderContract?.getUserReserveData(reservesListRaw[i], account)
            )
          }
          const userReservesDataRaw = await Promise.all(userReserveDataPromises)

          const response: UserReserveData[] = []
          for (let i = 0; i < userReservesDataRaw.length; i++) {
            response.push({
              usageAsCollateralEnabled: userReservesDataRaw[i].usageAsCollateralEnabled,
            })
          }

          setUserReservesData(response)
        } catch (error) {
          // setUserReservesData(defaultResponse)
          throw error
        }
      }
    }, [account])

    useEffect(() => {
      if (account) {
        fetchUserReservesData()
      } else {
        setUserReservesData(defaultResponse)
      }
      const refreshInterval = setInterval(fetchUserReservesData, 10000)
      return () => clearInterval(refreshInterval)
    }, [chainId, account, fetchUserReservesData, defaultResponse])

    return userReservesData
  }

  // const isUsingAsCollateral = (reserveId: number, userConfiguation: BigNumberJS): boolean => {
  //   try {
  //     return ((userConfiguation.toNumber() >> (reserveId * 2 + 1)) & 1) != 0
  //   } catch (e) {
  //     return false
  //   }
  // }

  return {
    useAAndVarTokens,
    useReserveData,
    useReservesData,
    useUserAccountData,
    useUserReserveData,
    useUserReservesData,
  }
}

export interface ReserveConfigurationMap {
  data: BigNumberJS
}

export interface ReserveData {
  configuration: ReserveConfigurationMap
  liquidityIndex: BigNumberJS
  variableBorrowIndex: BigNumberJS
  currentLiquidityRate: CurrencyAmount<Token>
  currentVariableBorrowRate: CurrencyAmount<Token>
  currentStableBorrowRate: CurrencyAmount<Token>
  lastUpdateTimestamp: string
  aTokenAddress: string
  stableDebtTokenAddress: string
  variableDebtTokenAddress: string
  interestRateStrategyAddress: string
  id: number
}

export interface ReserveDataWithBalance {
  liquidityIndex: BigNumberJS
  variableBorrowIndex: BigNumberJS
  currentLiquidityRate: CurrencyAmount<Token>
  currentVariableBorrowRate: CurrencyAmount<Token>
  currentStableBorrowRate: CurrencyAmount<Token>
  lastUpdateTimestamp: string
  aTokenAddress: string
  stableDebtTokenAddress: string
  variableDebtTokenAddress: string
  interestRateStrategyAddress: string
  id: number

  underlyingTokenAddress: string

  depositApy: CurrencyAmount<Token>
  depositIncentiveApy: CurrencyAmount<Token>
  borrowApy: CurrencyAmount<Token>
  borrowIncentiveApy: CurrencyAmount<Token>

  reserveConfiguration: ReserveConfiguration
  reserveData: ReserveDataProvider
}

export interface UserAccountData {
  totalCollateralETH: CurrencyAmount<Token>
  totalDebtETH: CurrencyAmount<Token>
  availableBorrowsETH: CurrencyAmount<Token>
  currentLiquidationThreshold: BigNumberJS
  ltv: BigNumberJS
  healthFactor: CurrencyAmount<Token>
}

export interface ReserveConfiguration {
  decimals: number
  ltv: BigNumberJS
  liquidationThreshold: BigNumberJS
  liquidationBonus: BigNumberJS
  reserveFactor: BigNumberJS
  usageAsCollateralEnabled: boolean
  borrowingEnabled: boolean
  stableBorrowRateEnabled: boolean
  isActive: boolean
  isFrozen: boolean
}

export interface ReserveDataProvider {
  availableLiquidity: CurrencyAmount<Token>
  totalStableDebt: CurrencyAmount<Token>
  totalVariableDebt: CurrencyAmount<Token>
  liquidityRate: BigNumberJS
  variableBorrowRate: BigNumberJS
  stableBorrowRate: BigNumberJS
  averageStableBorrowRate: BigNumberJS
  liquidityIndex: BigNumberJS
  variableBorrowIndex: BigNumberJS
  lastUpdateTimestamp: BigNumberJS
}

export interface UserReserveData {
  usageAsCollateralEnabled: boolean
}

export default useReserve
